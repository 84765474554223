import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logout from '../Logout';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as CLASSES from '../../constants/classes';

import { canAccessUsers } from '../helpers/permissions';

import doff from '../../assets/doff.gif';
import mitch from '../../assets/avatar.jpg';

const Navigation = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleClick = e => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const NavigationAuth = ({ authUser }) => (
    <div className={CLASSES.classes.navWrapper}>
      <div className="nav-inner text-sm lg:flex-grow">
        <Link to={ROUTES.HOME} className={CLASSES.classes.button} role="button">
          Dashboard
        </Link>
        {canAccessUsers(authUser) && (
          <Link
            to={ROUTES.ADMIN}
            className={CLASSES.classes.button}
            role="button"
          >
            Users
          </Link>
        )}
        <Link
          to={ROUTES.ACCOUNT}
          role="button"
          className={`${CLASSES.classes.button} lg:hidden `}
        >
          Settings
        </Link>

        <div className={`${CLASSES.classes.button} lg:hidden `}>
          <Logout />
        </div>

        <div
          className={`${
            CLASSES.classes.button
          } sub-nav-parent fixed hidden lg:inline-block`}
        >
          <img src={mitch} alt="mitch-avatar" className="h-5 inline mr-2" />
          Account
          <div className={'sub-nav hidden block absolute'}>
            <Link to={ROUTES.ACCOUNT} role="button">
              Settings
            </Link>
            <Logout />
          </div>
        </div>
      </div>
    </div>
  );

  const NavigationNonAuth = () => (
    <div className={CLASSES.classes.navWrapper}>
      <div className="nav-inner text-sm lg:flex-grow">
        <Link
          to={ROUTES.SIGN_UP}
          className={CLASSES.classes.button}
          role="button"
        >
          Sign Up
        </Link>
        <Link
          to={ROUTES.SIGN_IN}
          className={CLASSES.classes.accentedButton}
          role="button"
        >
          Login
        </Link>
      </div>
    </div>
  );

  return (
    <div
      className="sticky bg-white z-50 shadow"
      style={{ top: '0px' }}
      onClick={handleClick}
    >
      <AuthUserContext.Consumer>
        {authUser => (
          <nav
            className={`${CLASSES.classes.nav} ${
              menuVisible ? 'menu-visible' : 'menu-hidden'
            }`}
          >
            <div className="flex items-center flex-shrink-0 mr-6">
              <Link
                to={ROUTES.LANDING}
                role="button"
                className="font-semibold text-xl"
              >
                <img
                  src={doff}
                  alt="Doff Bitch"
                  className="nav-logo inline mr-2"
                />
                Give Him A Biscuit{' '}
              </Link>
            </div>
            <div className="lg:hidden">
              <button
                className={CLASSES.classes.button}
                onClick={() => {
                  setMenuVisible(prevState => !prevState);
                }}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>

            {authUser ? (
              <NavigationAuth authUser={authUser} />
            ) : (
              <NavigationNonAuth />
            )}
          </nav>
        )}
      </AuthUserContext.Consumer>
    </div>
  );
};
export default Navigation;
