import React, { Component } from 'react';

import UserList from './UserList';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import * as ROLES from '../../constants/roles';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      const filteredList = usersList.filter(user => {
        return user.active;
      });

      this.setState({
        users: filteredList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h1 className="text-center text-3xl mb-4">Users</h1>

        {users.length ? (
          <UserList users={users} loading={loading} />
        ) : (
          <div className="text-center text-xl mb-4">No users right now :(</div>
        )}
      </div>
    );
  }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default withFirebase(withAuthorization(condition)(AdminPage));
