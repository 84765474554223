import React, { Component } from 'react';
import { withAuthorization } from '../Session';

import Dictionary from '../Dictionary';

class HomePage extends Component {
  render() {
    return (
      <div className="lg:w-full lg:max-w-full p-1 md:p-5 m-auto">
        <Dictionary />
      </div>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);
