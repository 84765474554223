import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import * as CLASSES from '../../constants/classes';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
      <div className="lg:w-full lg:max-w-full p-1 md:p-5 m-auto">
        <form className={CLASSES.form.wrapper} onSubmit={this.onSubmit}>
          <label className={CLASSES.form.label} htmlFor="passwordOne">
            Password
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className={CLASSES.form.input}
            />
          </div>
          <label className={CLASSES.form.label} htmlFor="passwordTwo">
            Confirm Password
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
              className={CLASSES.form.input}
            />
          </div>
          <div className="flex items-center justify-between mt-6">
            <button
              className={
                isInvalid
                  ? CLASSES.classes.disabledButton
                  : CLASSES.classes.accentedButton
              }
              type="button"
              disabled={isInvalid}
              onClick={this.onSubmit}
            >
              Submit
            </button>
          </div>

          {error && (
            <p className="text-red-500 text-xs italic">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

export default withFirebase(PasswordChangeForm);
