import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';
import * as CLASSES from '../../constants/classes';
import * as ROLES from '../../constants/roles';

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  isAdmin: false,
};

const SignUpPage = () => (
  <div>
    <h1 className="text-3xl mb-4 text-center">Register</h1>
    <SignUpForm />
  </div>
);

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleSubmit = event => {
    const { email, passwordOne } = this.state;
    const roles = {};

    roles[ROLES.EDITOR] = ROLES.EDITOR;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          email,
          active: true,
          wordCount: 0,
          roles,
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '' || email === '';

    return (
      <div className="lg:w-full lg:max-w-full p-1 md:p-5 m-auto">
        <form className={CLASSES.form.wrapper} onSubmit={this.handleSubmit}>
          <label className={CLASSES.form.label} htmlFor="email">
            Email
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              className={CLASSES.form.input}
            />
          </div>
          <label className={CLASSES.form.label} htmlFor="passwordOne">
            Password
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className={CLASSES.form.input}
            />
          </div>

          <label className={CLASSES.form.label} htmlFor="passwordTwo">
            Confirm Password
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
              className={CLASSES.form.input}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className={
                isInvalid
                  ? CLASSES.classes.disabledButton
                  : CLASSES.classes.accentedButton
              }
              type="button"
              disabled={isInvalid}
              onClick={this.handleSubmit}
            >
              Sign Up
            </button>
          </div>
          {error && (
            <p className="text-red-500 text-xs italic mt-3">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <p className="text-center mt-4">
    Don't have an account?{' '}
    <Link to={ROUTES.SIGN_UP} className="text-purple-500">
      Sign Up
    </Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
