import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as CLASSES from '../../constants/classes';

const LoginPage = () => (
  <div>
    <h1 className="text-3xl mb-4 text-center">Log In</h1>
    <LoginForm />
    <SignUpLink />
    <PasswordForgetLink />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class LoginFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="lg:w-full lg:max-w-full p-1 md:p-5 m-auto">
        <form className={CLASSES.form.wrapper} onSubmit={this.onSubmit}>
          <label className={CLASSES.form.label} htmlFor="email">
            Email
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              className={CLASSES.form.input}
            />
          </div>
          <label className={CLASSES.form.label} htmlFor="password">
            Password
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className={CLASSES.form.input}
            />
          </div>
          <div className="flex items-center justify-between mt-6">
            <button
              className={
                isInvalid
                  ? CLASSES.classes.disabledButton
                  : CLASSES.classes.accentedButton
              }
              type="button"
              disabled={isInvalid}
              onClick={this.onSubmit}
            >
              Login
            </button>
          </div>
          {error && (
            <p className="text-red-500 text-xs italic">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

const LoginForm = withRouter(withFirebase(LoginFormBase));

export default LoginPage;

export { LoginForm };
