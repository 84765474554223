import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import identify from '../helpers/logRocket';

const config = {
  apiKey: 'AIzaSyCrTB5KBxjB1vVok2D-cVSE11AixGlK3FI',
  authDomain: 'give-him-a-biscuit.firebaseapp.com',
  databaseURL: 'https://give-him-a-biscuit.firebaseio.com',
  projectId: 'give-him-a-biscuit',
  storageBucket: '',
  messagingSenderId: '907883418852',
  appId: '1:907883418852:web:8ae86354c72c920d',
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.serverValue = app.database.ServerValue;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doDeleteUser = () => this.auth.delete();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            identify(authUser);
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);

  userWordCount = uid => this.db.ref(`users/${uid}/wordCount`);

  users = () => this.db.ref('users');

  // *** Word API ***
  setWord = () => this.db.ref('word');

  word = id => this.db.ref(`word/${id}`);

  words = () => this.db.ref('word');

  wordDoffs = id => this.db.ref(`word/${id}/doffs`);

  filterWords = query =>
    this.db
      .ref('word')
      .orderByChild('acronym')
      .equalTo(query)
      .once('value');
}

export default Firebase;
