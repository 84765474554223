import React, { useState, useEffect } from 'react';

import DictionaryList from './DictionaryList';
import DictionarySearch from './DictionarySearch';
import DictionaryForm, { ShowAddFormButton } from './DictionaryForm';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import { canAddToDictionary } from '../helpers/permissions';

const mapObjectToArray = object => {
  if (!object) return [];

  return Object.keys(object).map(key => ({
    ...object[key],
    id: key,
  }));
};

const Dictionary = props => {
  const [formVisible, setFormVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wordList, setWordList] = useState([]);
  let wordArray = mapObjectToArray(wordList);

  useEffect(
    () => {
      if (!wordArray.length) {
        setLoading(true);
        props.firebase
          .words()
          .orderByChild('editedAt')
          .on('value', snapshot => {
            const wordList = snapshot.val();

            if (!wordList) return;
            setWordList(wordList);
            setLoading(false);
          });
      }
    },
    [props.firebase, wordList, loading, wordArray.length],
  );

  const onFormSubmit = () => {
    setFormVisible(false);
  };

  const sortWordList = sortBy => {
    let wordList = [];

    props.firebase
      .words()
      .orderByChild(sortBy)
      .on('child_added', data => {
        wordList.push(data.val());

        setWordList(wordList);
      });
  };

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div>
          <div
            className={`${
              canAddToDictionary(authUser)
                ? 'justify-between'
                : 'justify-center'
            } flex m-auto max-w-4xl`}
          >
            {canAddToDictionary(authUser) && (
              <ShowAddFormButton
                handleClick={() => {
                  setFormVisible(true);
                }}
              />
            )}
            <DictionarySearch setWordList={setWordList} />
          </div>

          <DictionaryList
            authUser={authUser}
            words={wordArray}
            loading={loading}
            sortWordList={sortWordList}
          />

          {formVisible ? (
            <DictionaryForm
              onFormSubmit={onFormSubmit}
              authUser={authUser}
              handleFormClose={() => {
                setFormVisible(false);
              }}
            />
          ) : null}
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(Dictionary);
