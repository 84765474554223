import * as ROLES from '../../constants/roles';

export const canAccessUsers = user => {
  return !!user && !!user.roles[ROLES.ADMIN];
};

export const canEditDictionary = user => {
  return !!user && (!!user.roles[ROLES.EDITOR] || !!user.roles[ROLES.ADMIN]);
};

export const canDeleteDictionary = user => {
  return !!user && !!user.roles[ROLES.ADMIN];
};

export const canAddToDictionary = user => {
  return !!user && (!!user.roles[ROLES.EDITOR] || !!user.roles[ROLES.ADMIN]);
};

export const canDoff = user => {
  return !!user && (!!user.roles[ROLES.EDITOR] || !!user.roles[ROLES.ADMIN]);
};
