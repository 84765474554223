import React from 'react';

const Loader = ({ randomMitch }) => {
  const mitch = require(`../../assets/mitch${randomMitch}.jpg`);

  return (
    <div className="mt-20 m-auto spinner-image">
      <img alt="Mitch Loader" src={mitch} />
    </div>
  );
};

export default Loader;
