import React from 'react';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import DeleteUserLink from './DeleteUserLink';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => {
      return (
        <div className="lg:w-full lg:max-w-lg p-1 lg:p-5 m-auto">
          <h1 className="text-center text-2xl mb-4">
            Account: {authUser.email}
          </h1>

          <div className="text-center">
            <h2 className="inline">Your permissions:</h2>
            <ul className="inline-flex ml-1">
              {Object.keys(authUser.roles).map(role => (
                <li className="ml-1">{role}</li>
              ))}
            </ul>
          </div>

          <hr className="border" />
          <h1 className="text-center text-xl mb-4">Reset Password</h1>
          <PasswordChangeForm />
          <DeleteUserLink />
        </div>
      );
    }}
  </AuthUserContext.Consumer>
);
const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
