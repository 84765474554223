import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import * as CLASSES from '../../constants/classes';

const INITIAL_STATE = {
  query: '',
  filtered: false,
};

class DictionarySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { query } = this.state;

    this.props.firebase.filterWords(query).then(data => {
      this.props.setWordList(data.val());
      this.setState({ filtered: true });
    });
  };

  handleCancel = event => {
    event.preventDefault();

    this.props.firebase.filterWords('').then(data => {
      this.props.setWordList(data.val());

      this.setState({ ...INITIAL_STATE });
    });
  };

  render() {
    const { query, error } = this.state;

    return (
      <div>
        <form className="flex" onSubmit={this.handleSubmit}>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="query"
              value={query}
              onChange={this.onChange}
              type="text"
              placeholder="Filter by acronym..."
              className={CLASSES.form.input}
            />
          </div>

          <div className="flex">
            <button
              className={CLASSES.classes.accentedButton}
              type="submit"
              onClick={this.handleSubmit}
            >
              <span role="img" aria-label="search">
                🎩
              </span>
            </button>
            {this.state.filtered && (
              <button
                className={CLASSES.classes.button}
                type="submit"
                onClick={this.handleCancel}
              >
                <span role="img" aria-label="search">
                  ⓧ
                </span>
              </button>
            )}
          </div>
          {error && (
            <p className="text-red-500 text-xs italic mt-3">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

export default withFirebase(DictionarySearch);
