import React, { Component } from 'react';
import * as CLASSES from '../../constants/classes';

import anchorme from 'anchorme';
import { withFirebase } from '../Firebase';

import {
  canEditDictionary,
  canDeleteDictionary,
  canDoff,
} from '../helpers/permissions';

class DictionaryWord extends Component {
  constructor(props) {
    super(props);

    this.textInput = null;

    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };

    this.wordDoffRef = this.props.firebase.wordDoffs(this.props.word.id);

    this.state = {
      editMode: false,
      editWord: this.props.word,
      doffs: this.props.word.doffs,
    };
  }

  componentDidMount(props) {
    this._bindDoffRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.authUser !== prevProps.authUser) {
      this._bindDoffRef();
    }
  }

  _bindDoffRef() {
    if (!this.props.authUser) return;

    this.wordDoffRef.on('value', snapshot => {
      let hasDoffed = false;

      const doffs = snapshot.val();
      if (doffs && doffs.ids) {
        hasDoffed = doffs.ids.includes(this.props.authUser.uid);
      }

      this.setState({
        hasDoffed,
        doffs,
      });
    });
  }

  onToggleEditMode = event => {
    this.setState(
      state => ({
        editMode: !state.editMode,
        editWord: this.props.word,
      }),
      () => {
        this.focusTextInput();
      },
    );
  };

  onSaveEditWord = event => {
    this.props.onEditWord(this.props.word, this.state.editWord);

    this.setState({ editMode: false });
  };

  handleDeleteClick = event => {
    this.props.onRemoveWord(this.props.word);
  };

  handleResetClick = event => {
    this.setState({ editMode: false });
  };

  onChange = event => {
    const name = event.target.name;

    this.setState({ editWord: { [name]: event.target.value } });
  };

  onDoffClick = event => {
    const { authUser } = this.props;

    event.preventDefault();
    this.wordDoffRef.transaction(doff => {
      let nextCount = 0;
      let doffIds = [];

      if (doff && doff.ids) {
        doffIds = doff.ids;
      }

      if (doff && doff.ids && doff.ids.includes(authUser.uid)) {
        doffIds = doff.ids.filter(value => value !== authUser.uid);
      } else {
        doffIds.push(authUser.uid);
      }

      nextCount = doffIds.length;

      return {
        count: nextCount,
        ids: doffIds,
      };
    });
  };

  renderAnchormeText(text) {
    if (text) {
      return {
        __html: anchorme(text),
      };
    }
  }

  renderDoffCount() {
    const { doffs } = this.state;
    const doffsCount = doffs && doffs.count;
    let icon;

    switch (doffsCount) {
      case 0:
        icon = '';
        break;
      case 1:
        icon = '🥇';
        break;
      case 2:
        icon = '🥈';
        break;
      case 3:
        icon = '🥉';
        break;
      case 4:
        icon = '🏆';
        break;
      default:
        icon = doffsCount;
    }

    if (doffsCount > 0) {
      return (
        <span data-doffs={doffsCount} className="m-auto">
          {icon}
        </span>
      );
    }
  }

  render() {
    const { word, authUser } = this.props;
    const { editMode, editWord, hasDoffed } = this.state;

    return (
      <tr key={word.id}>
        <td className={CLASSES.table.cell}>
          {editMode ? (
            <input
              type="text"
              name="acronym"
              value={editWord.acronym}
              onChange={this.onChange}
              className="block fixed"
              ref={this.setTextInputRef}
            />
          ) : (
            word.acronym
          )}
        </td>
        <td className={CLASSES.table.cell}>
          {editMode ? (
            <input
              type="text"
              name="description"
              value={editWord.description}
              onChange={this.onChange}
              className="block fixed"
            />
          ) : (
            <div
              dangerouslySetInnerHTML={this.renderAnchormeText(
                word.description,
              )}
            />
          )}
        </td>
        <td className={`${CLASSES.table.cell} hidden md:table-cell`}>
          {editMode ? (
            <input
              type="text"
              name="origin"
              value={editWord.origin}
              onChange={this.onChange}
              className="block fixed"
            />
          ) : (
            <div
              dangerouslySetInnerHTML={this.renderAnchormeText(word.origin)}
            />
          )}
        </td>

        {canDoff(authUser) && (
          <td className={`${CLASSES.table.cell}`}>{this.renderDoffCount()}</td>
        )}

        {!editMode ? (
          canDoff(authUser) && (
            <td className={`${CLASSES.table.cell} w-20`}>
              <div className="flex justify-center">
                <button
                  className={`doff-button font-bold ${
                    hasDoffed ? 'de-doff' : 'doff'
                  }`}
                  onClick={this.onDoffClick}
                >
                  <span
                    role="img"
                    aria-label={`${hasDoffed ? 'de-doff' : 'Doff'}`}
                  >
                    {hasDoffed ? 'De-Doff' : 'Doff'}
                  </span>
                </button>
              </div>
            </td>
          )
        ) : (
          <td
            className={CLASSES.table.cell}
            role="button"
            onClick={this.handleResetClick}
          >
            Cancel
            <span role="img" aria-label="reset">
              💦
            </span>
          </td>
        )}
        {!editMode
          ? canEditDictionary(authUser) && (
              <td
                className={CLASSES.table.cell}
                role="button"
                onClick={this.onToggleEditMode}
              >
                Edit
              </td>
            )
          : canEditDictionary(authUser) && (
              <td
                className={CLASSES.table.cell}
                role="button"
                onClick={this.onSaveEditWord}
              >
                Save
                <span role="img" aria-label="save">
                  🧠
                </span>
              </td>
            )}
        {canDeleteDictionary(authUser) && (
          <td
            className={CLASSES.table.cell}
            role="button"
            onClick={this.handleDeleteClick}
          >
            Delete
            <span role="img" aria-label="delete">
              🚽
            </span>
          </td>
        )}
      </tr>
    );
  }
}

export default withFirebase(DictionaryWord);
