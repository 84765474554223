import React, { Component, Fragment } from 'react';
import { table } from '../../constants/classes';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  render() {
    return (
      <Fragment>
        {this.state.error && <p>{this.state.error.message}</p>}
        {this.props.loading ? (
          <div className="text-center text-xl mb-4">Loading ...</div>
        ) : (
          <table className={table.table}>
            <thead className="">
              <tr>
                <th className={table.cell}>Email</th>
                <th className={table.cell}>Roles</th>
                <th className={table.cell}>Biscuits Given</th>
              </tr>
            </thead>
            <tbody>
              {this.props.users.map(user => (
                <tr key={user.uid}>
                  <td className={table.cell} title={user.id}>
                    {user.email}
                  </td>
                  <td className={table.cell}>
                    <ul className="inline-flex mr-1">
                      {user.roles &&
                        Object.keys(user.roles).map(role => (
                          <li className="ml-1">{role}</li>
                        ))}
                    </ul>
                  </td>

                  <td className={table.cell}>{user.wordCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Fragment>
    );
  }
}

export default UserList;
