export const form = {
  wrapper:
    'bg-white max-w-lg p-4 flex flex-col justify-between m-auto block fixed',
  input: 'appearance-none py-2 px-3 text-gray-700 focus:outline-none w-full',
  inputWrapper: 'fixed wrapper block overflow-hidden',
  label: ' text-gray-700 text-sm font-bold mb-2 mt-4',
};

export const table = {
  table: 'max-w-5xl rounded overflow-hidden m-auto mt-10',
  cell: 'p-2 border-2 border-black ',
};

export const classes = {
  button: ' font-semibold block inline-block',
  accentedButton: ' font-semibold block accent inline-block',
  disabledButton: ' font-semibold block opacity-50 cursor-not-allowed',
  nav: 'flex items-center justify-between flex-wrap flex-row p-6',
  navWrapper:
    'navigation-wrapper w-full items-end lg:flex lg:items-center lg:w-auto hidden',
};
