import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

class DeleteUserLink extends Component {
  onClick = () => {
    const user = this.props.firebase.auth.currentUser;
    this.props.firebase
      .user(user.uid)
      .update({
        active: false,
      })
      .then(() => {
        this.props.firebase.doDeleteUser().then(() => {
          this.props.history.push(ROUTES.HOME);
        });
      });
  };

  render() {
    return (
      <div className="mt-10 max-w-lg m-auto">
        <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
          Danger Zone
        </div>
        <div className="bg-red-100 px-4 py-3 text-red-700">
          To completely remove your account click here:{' '}
          <button className="font-bold" onClick={this.onClick}>
            Delete Account
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(withFirebase(DeleteUserLink));
