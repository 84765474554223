import React, { Component } from 'react';
import Dictionary from '../Dictionary';

class LandingPage extends Component {
  render() {
    return (
      <div className="lg:w-full p-1 lg:p-5 m-auto">
        <Dictionary />
      </div>
    );
  }
}

export default LandingPage;
