import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as CLASSES from '../../constants/classes';

const PasswordForgetPage = () => (
  <div>
    <h1 className="text-center text-3xl mb-4">Reset Your Password</h1>
    <p className="text-center mb-4">
      We will send you an email with instructions
    </p>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div className="lg:w-full lg:max-w-full p-1 md:p-5 m-auto">
        <form className={CLASSES.form.wrapper} onSubmit={this.onSubmit}>
          <label className={CLASSES.form.label} htmlFor="email">
            Email
          </label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            className={CLASSES.form.input}
          />
          <div className="flex items-center justify-between mt-6">
            <button
              className={
                isInvalid
                  ? CLASSES.classes.disabledButton
                  : CLASSES.classes.accentedButton
              }
              type="button"
              disabled={isInvalid}
              onClick={this.onSubmit}
            >
              Submit
            </button>
          </div>

          {error && (
            <p className="text-red-500 text-xs italic">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="text-center mt-4 text-purple-500 hover:text-purple-600">
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
