import React from 'react';
import ReactDOM from 'react-dom';
import 'blocks.css';
import './index.css';
import App from './components/App/index';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('ziqz48/give-him-a-biscuit');
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
