import React, { Component, Fragment } from 'react';
import * as CLASSES from '../../constants/classes';
import DictionaryWord from './DictionaryWord';

import { withFirebase } from '../Firebase';

import {
  canEditDictionary,
  canDeleteDictionary,
  canDoff,
} from '../helpers/permissions';

import MitchLoader from '../snippets/MitchLoader';
import randomNumber from '../helpers/random';

class DictionaryList extends Component {
  constructor(props) {
    super(props);

    this.randomMitch = randomNumber(3);

    this.state = {
      error: false,
      loading: false,
      words: props.words,
    };
  }

  onRemoveWord = word => {
    if (window.confirm(`Are you sure you want to delete ${word.acronym}`)) {
      this.props.firebase.word(word.id).remove();
    }
  };

  onEditWord = (word, editWord) => {
    this.saveWord({
      ...word,
      ...editWord,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  saveWord = word => {
    this.props.firebase.word(word.id).set(word);
  };

  render() {
    const { authUser, words, loading, sortWordList } = this.props;

    return (
      <Fragment>
        {this.state.error && <p>{this.state.error.message}</p>}
        {loading ? (
          <MitchLoader randomMitch={this.randomMitch} />
        ) : (
          <table className={CLASSES.table.table}>
            <thead className="border-gray-400">
              <tr>
                <th
                  className={`${CLASSES.table.cell} cursor-pointer`}
                  onClick={() => sortWordList('acronym')}
                >
                  Acronym
                </th>
                <th
                  className={`${CLASSES.table.cell} cursor-pointer`}
                  onClick={() => sortWordList('description')}
                >
                  Description
                </th>
                <th className={`${CLASSES.table.cell} hidden md:table-cell`}>
                  Origin
                </th>
                {canDoff(authUser) && (
                  <th className={CLASSES.table.cell}>
                    <span role="img" aria-label="Doffs" className="w-24">
                      🏆
                    </span>
                  </th>
                )}
                {canDoff(authUser) && (
                  <th className={CLASSES.table.cell}>
                    <span role="img" aria-label="Doff " className="w-24">
                      🎩
                    </span>
                  </th>
                )}
                {canEditDictionary(authUser) && (
                  <th className={CLASSES.table.cell} />
                )}
                {canDeleteDictionary(authUser) && (
                  <th className={CLASSES.table.cell} />
                )}
              </tr>
            </thead>
            <tbody>
              {words &&
                words.map(word => (
                  <DictionaryWord
                    key={word.id}
                    word={word}
                    onRemoveWord={this.onRemoveWord}
                    onEditWord={this.onEditWord}
                    authUser={authUser}
                  />
                ))}
            </tbody>
          </table>
        )}
      </Fragment>
    );
  }
}

export default withFirebase(DictionaryList);
