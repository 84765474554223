import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import * as CLASSES from '../../constants/classes';

const INITIAL_STATE = {
  acronym: '',
  description: '',
  origin: '',
  error: null,
  loading: false,
};

class DictionaryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCloseForm = event => {
    event.preventDefault();
    this.props.handleFormClose();
  };

  handleSubmit = event => {
    const { acronym, description, origin } = this.state;
    const { firebase, authUser } = this.props;
    const userWordCount = firebase.userWordCount(authUser.uid);

    userWordCount.transaction(count => {
      return count + 1;
    });

    firebase
      .setWord()
      .push({
        acronym,
        description,
        origin,
        doffs: 0,
        userId: authUser.uid,
        active: true,
        createdAt: firebase.serverValue.TIMESTAMP,
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    this.props.onFormSubmit();
    event.preventDefault();
  };

  render() {
    const { acronym, description, origin, error } = this.state;

    const isInvalid = acronym === '' || description === '';

    return (
      <div
        className="absolute inset-0 p-1 pt-20 md:p-20 z-50"
        style={{ backgroundColor: 'rgba(113, 128, 150, 0.6)' }}
      >
        <form className={CLASSES.form.wrapper} onSubmit={this.handleSubmit}>
          <label className={CLASSES.form.label} htmlFor="acronym">
            Acronym
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="acronym"
              value={acronym}
              onChange={this.onChange}
              type="text"
              placeholder="TYFYS"
              className={CLASSES.form.input}
            />
          </div>

          <label className={CLASSES.form.label} htmlFor="description">
            Description
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="description"
              value={description}
              onChange={this.onChange}
              type="text"
              placeholder="Thank You For Your Service"
              className={CLASSES.form.input}
            />
          </div>

          <label className={CLASSES.form.label} htmlFor="origin">
            Origin
          </label>
          <div className={CLASSES.form.inputWrapper}>
            <input
              name="origin"
              value={origin}
              onChange={this.onChange}
              type="text"
              placeholder="Stolen Valour"
              className={CLASSES.form.input}
            />
          </div>
          <div className="flex items-center justify-between border-t-4 p2 mt-4 pt-4 border-gray-700">
            <button
              className={
                isInvalid
                  ? CLASSES.classes.disabledButton
                  : CLASSES.classes.accentedButton
              }
              type="submit"
              disabled={isInvalid}
              onClick={this.handleSubmit}
            >
              Give Him A Biscuit
            </button>
            <button
              className={CLASSES.classes.button}
              type="button"
              onClick={this.handleCloseForm}
            >
              Cancel
            </button>
          </div>
          {error && (
            <p className="text-red-500 text-xs italic mt-3">{error.message}</p>
          )}
        </form>
      </div>
    );
  }
}

export const ShowAddFormButton = props => (
  <button
    className={CLASSES.classes.accentedButton}
    type="button"
    onClick={props.handleClick}
  >
    Add Entry
  </button>
);

export default withFirebase(DictionaryForm);
